import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  FontAwesome5,
  Select
} from '../../../Common'

import './LocationSelect.css'

class LocationSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.onReset = this.onReset.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false, filled: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
    let { options, form, value } = this.props
    options = options || []
    form = form || { value: '' }
    let locationValue
    const inputValue = value !== undefined ? value : form.value
    const formOptions = options?.map(item => ({ value: item.id, rawValue: item, label: item.name }))
    if (options.length) {
      locationValue = formOptions.find(({ label: optionLabel, value: optionValue }) => optionValue.includes(inputValue) || optionLabel.includes(inputValue))?.value
    } else {
      locationValue = form?.options?.find(({ label: optionLabel, value: optionValue }) => optionValue.includes(inputValue) || optionLabel.includes(inputValue))?.value
    }
    if (!!locationValue) this.setState({ filled: true })
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  async getOptions (value) {
    let { name, form, branches, formName } = this.props
    form = form || { value }
    this.setState({ filled: !!value })
    const options = branches.filter((branch) => {
      const { rawValue, label } = branch || {}
      const { address } = rawValue || {}
      const { formatted, zipCode } = address || {}
      const searchTerm = value.trim().toLowerCase()

      return label?.toLowerCase().includes(searchTerm) || (zipCode && zipCode?.toLowerCase().includes(searchTerm)) || (formatted && formatted?.toLowerCase().includes(searchTerm))
    })
    handlers.formFieldsUpdate(
      formName, {
        [name]: {
          ...form,
          value,
          options
        }
      }
    )
    return options
  }

  onReset () {
    let { name, form, branches, formName } = this.props
    form = form || { value: '' }
    handlers.formFieldsUpdate(formName, {
      [name]: {
        ...form,
        value: '',
        options: branches,
        selectedId: null,
        customer: null
      }
    })
    this.setState({ filled: false })
  }

  onChange (item) {
    let { name, form, formName } = this.props
    const { value } = item
    form = form || { value }
    handlers.formFieldsUpdate(formName, {
      [name]: {
        ...form,
        value
      }
    })
    if (!!value) {
      this.setState({ filled: true })
    }
  }

  render () {
    const { focused } = this.state
    let {
      label,
      placeholder,
      name,
      disabled,
      className,
      hideError,
      value,
      form,
      options
    } = this.props
    form = form || { value: '' }
    options = options || []
    const classNames = ['ta-booking-customer-select']
    if (disabled) classNames.push('disabled')
    if (className) classNames.push(className)
    let locationValue
    const inputValue = value !== undefined ? value : form.value
    const formOptions = options?.map(item => ({ value: item.id, rawValue: item, label: item.name }))
    if (options.length) {
      locationValue = formOptions.find(({ label: optionLabel, value: optionValue }) => optionValue.includes(inputValue) || optionLabel.includes(inputValue))?.value
    } else {
      locationValue = form.options.find(({ label: optionLabel, value: optionValue }) => optionValue.includes(inputValue) || optionLabel.includes(inputValue))?.value
    }

    return (
      <div className={classNames.join(' ')}>
        <FormGroup
          focused={focused}
          filled={this.state.filled}
          labelText={label || form.label}
        >
          <Select
            name={name}
            value={inputValue}
            placeholder={placeholder || form.placeholder}
            options={formOptions.length ? formOptions : form.options}
            hideArrow
            searchable
            clearable
            hideNoResults
            getOptions={this.getOptions}
            onReset={this.onReset}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            disabled={disabled || form.disabled}
            renderItem={(item, index, { isActive, isSelected, onSelect }) => {
              const itemClassNames = ['ta-booking-location-select__options__item']
              if (isActive) itemClassNames.push('active')
              if (isSelected) itemClassNames.push('selected')
              return (
                <div className={itemClassNames.join(' ')} onClick={onSelect}>
                  <div className='ta-booking-location-select__options__name'>
                    {item.label}
                  </div>
                  {item.rawValue && item.rawValue.address && (
                    <div className='ta-booking-location-select__options__address'>
                      <FontAwesome5 icon='map-marker-alt' type='solid' /> {item.rawValue.address.formatted}
                    </div>
                  )}
                </div>
              )
            }}
          />
          {(!hideError &&
            <Error name={name} />)}
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  bookingCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }),
  serviceId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'selectedId' }),
  services: state.services.list,
  branches: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'allOptions' })
})

export default feedContextInProps(connect(maps)(LocationSelect), FormContext)
