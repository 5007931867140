import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { SelectTag } from '../../Common'

const SelectTags = (props) => {
  let {
    inline,
    values,
    options,
    disabled,
    disabledRemove,
    onDelete,
    renderSelectedItem
  } = props
  values = values || []
  disabledRemove = disabledRemove || []
  options = options || []
  const items = useMemo(() =>
    options.filter(item => values.includes(item.value)),
  [options, values])
  const classNames = ['ta-select-multi__values']
  if (inline) classNames.push('inline')

  if (items.length === 0) return null
  return (
    <div className={classNames.join(' ')}>
      {items.map((item, index) => {
        const isRemoveDisabled = disabledRemove.includes(item.value)
        return (
          <SelectTag
            key={index}
            item={item}
            index={index}
            disabled={disabled || isRemoveDisabled}
            onDelete={onDelete}
            renderSelectedItem={renderSelectedItem}
          />
        )
      })}
    </div>
  )
}

SelectTags.propTypes = {
  inline: PropTypes.bool,
  values: PropTypes.array,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onDelete: PropTypes.func,
  renderSelectedItem: PropTypes.func
}

export default SelectTags
