import React from 'react'
import PropTypes from 'prop-types'
import {
  SectionTitle,
  IconListItem,
  Form,
  Error,
  t,
  BorderedBox,
  IconListItemTitle,
  IconListItemBody,
  format,
  Alert,
  Button,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  FontAwesome5,
  SimpleListItemButtons,
  ListButton,
  Spinner,
  Checkbox,
  Row,
  Col
} from '../../../Common'
import {
  CustomerPreviewDetailsField,
  CustomerPreviewDetailsTagItem,
  CustomerPreviewEventResources
} from '../../../Beauties'
import { dangerousHTML } from '../../../Utils'
import { PopupTriggerWrapper } from '../../../Beasts'
import momenttz from 'moment-timezone'

const CustomerPreviewDetails = props => {
  let {
    customer,
    hideGoogleMap,
    message,
    bookings,
    onEventRebook,
    timezones,
    toggleUserDetails,
    eventTypes,
    shownCustomerDetailsId,
    isScheduleForm,
    isCancellationForm,
    fieldsToBeDeleted,
    onCancelBookings,
    branches: branchesList,
    bookingsType,
    displayBookedResources
  } = props
  customer = customer || {}
  const isPastBooking = bookingsType === 'PAST'
  const isDeletedBooking = bookingsType === 'DELETED'
  const classNames = ['ta-customer-preview-booking-item']
  if (isPastBooking) classNames.push('past')
  if (isDeletedBooking) classNames.push('deleted')
  const defaultCategory = (customer.groupedFields || []).find(item => item.isDefault) || { fields: [] }
  const otherCategories = (customer.groupedFields || []).filter(item => !item.isDefault) || []
  const defaultIdFilters = ['added', 'birthday', 'mobilePhone']
  const defaultFields = defaultCategory.fields.filter(item =>
    shownCustomerDetailsId === customer.id || item.isMandatoryOffline || defaultIdFilters.includes(item.defaultId)
  )
  const otherCategoriesFiltered = otherCategories.filter(({ fields }) =>
    fields.some(item => shownCustomerDetailsId === customer.id || item.isMandatoryOffline || defaultIdFilters.includes(item.defaultId))
  )
  const { bookedCompanies } = customer || {}
  const userPreviewProps = { name: customer.fullName || customer.companyName || '' }
  if (customer.isAvatarActive) userPreviewProps.avatarUrl = customer.avatarUrl

  return (
    <div className='ta-customers__preview__bookings-wrapper'>
      {(message &&
        <Alert theme={message.type} label={t('errors.somethingWentWrong')}>{message.text && t(message.text)}</Alert>
      )}
      <div className='ta-customers__preview__bookings'>
        <BorderedBox>
          <Form name='customerPreview'>
            <Error name='globalErrors' noTopOffset />
          </Form>
          {(customer.createdAt &&
            <IconListItem>
              <IconListItemTitle width='128px'>{t('global.created')}:</IconListItemTitle>
              <IconListItemBody offset='131px'>{format(customer.createdAt, 'long')}</IconListItemBody>
            </IconListItem>
          )}
          {(customer.externalId &&
            <IconListItem>
              <IconListItemTitle width='128px'>{t('global.externalId')}</IconListItemTitle>
              <IconListItemBody offset='131px'>{customer.externalId}</IconListItemBody>
            </IconListItem>
          )}
          {defaultFields.map(item => (
            <CustomerPreviewDetailsField
              timezones={timezones}
              hideGoogleMap={hideGoogleMap}
              item={item}
              key={item.id}
              showIcon
            />
          ))}
          {otherCategoriesFiltered.map(category => (
            <div key={category.id}>
              <SectionTitle label={category.name || t(category.translationKey)} icon='sliders-h' />
              {category.fields.map(item => (
                <CustomerPreviewDetailsField
                  timezones={timezones}
                  hideGoogleMap={hideGoogleMap}
                  item={item}
                  key={item.id}
                />
              ))}
            </div>
          ))}
          {((customer.tags || []).length > 0 &&
            <>
              <SectionTitle label={t('global.tags')} icon='tag' />
              {customer.tags.map(item => (
                <CustomerPreviewDetailsTagItem key={item.id} name={item.name} color={item.color} />
              ))}
            </>
          )}
          <Button
            className='ta-customers__preview_booking_details_button'
            primary
            type='button'
            greyGhost
            block
            onClick={() => toggleUserDetails(shownCustomerDetailsId === customer.id ? null : customer.id)}
          >
            {t(shownCustomerDetailsId === customer.id
              ? 'bookings.customerPreview.customerDetails.hide.label'
              : 'bookings.customerPreview.customerDetails.label'
            )}
          </Button>

          <div className='ta-customers__preview_booking_branches_container'>
            {(bookedCompanies && bookedCompanies.length > 0 &&
              <>
                <div className='ta-customers__preview_booking_bookings_title'>{t('global.locations')}</div>
                {bookedCompanies.map((item, index) => (
                  <IconListItem key={item.id}>
                    <IconListItemBody>{item.name}</IconListItemBody>
                  </IconListItem>
                ))}
              </>
            )}
          </div>
          <Form name='booking'>
            {eventTypes.map(type => {
              const currentBookings = bookings[type]
              const bookingsList = currentBookings.list || []
              const bookingLength = !!bookingsList.length
              const { pending, loadMoreBookings, isBookingsLastPage } = currentBookings
              const isUpcoming = type === 'upcoming'
              const hoverTextKey = isScheduleForm ? 'bookings.scheduleAppointment.popup.content' : 'bookings.rescheduleAppointment.popup.content'
              const icon = isScheduleForm ? 'calendar-plus' : 'calendar-day'
              const translationKey = isUpcoming ? 'bookings.customerPreview.upcoming.label' : 'bookings.customerPreview.past.label'
              return (
                <div key={type}>
                  <div className='ta-customers__preview_booking_bookings_title'>{t(translationKey)}</div>
                  {(bookingLength &&
                    <>
                      <div className='ta-customers__bookings-container'>
                        {bookingsList.map((item, index) => {
                          const dateParts = item.interval.from.split(' ')
                          const date = dateParts[0]
                          const time = dateParts[1]
                          const extraTitle = item.isBatch ? ` (${item.interval.batchNumber + 1}/${item.batchCount})` : ''
                          const bookingDuration = isDeletedBooking ? item.durationsPattern : item.duration
                          const eventBranch = branchesList.find(({ id }) => id === item.companyId) || {}
                          const timezone = eventBranch.timezone
                          const branchName = eventBranch.name
                          const region = eventBranch.region
                          let service = item.service
                          if (!service) {
                            service = {
                              id: item.serviceId,
                              combination: item.combination,
                              name: item.title,
                              companyId: item.companyId,
                              duration: item.duration,
                              durationAfter: item.durationAfter,
                              durationBefore: item.durationBefore,
                              durationsPattern: item.durationsPattern
                            }
                          }
                          const listButtonClassNames = ['ta-btn-primary']

                          return (
                            <div className='ta-customers__preview_booking_container' key={index}>
                              <SimpleListItem className={classNames.join(' ')}>
                                {(isUpcoming && isCancellationForm &&
                                  <SimpleListItemContent>
                                    <div className='ta-customers__preview__bookings__checkbox'>
                                      <Checkbox name={`bookingForDeletion${item.id}`} />
                                    </div>
                                  </SimpleListItemContent>
                                )}
                                <SimpleListItemContent>
                                  <div className='ta-customers__preview__bookings__timestamp'>
                                    <ul>
                                      <li className='ta-customers__preview__date'>{format(date, 'DD.MM.YYYY', { timezone })}</li>
                                      <li className='ta-customers__preview__time'>{momenttz.utc(item.interval.from, 'YYYY-MM-DD HH:mm').tz(timezone).format('HH:mm')}</li>
                                    </ul>
                                  </div>
                                </SimpleListItemContent>
                                <SimpleListItemContent>
                                  <div className='ta-customers__preview__bookings__content'>
                                    <SimpleListItemTitle title={`${item.title}${extraTitle}`} />
                                    {branchName && <div className='ta-customers__preview_booking_address'><SimpleListItemContent>{branchName}</SimpleListItemContent></div>}
                                    <ul className='ta-list-inline'>
                                      <li><FontAwesome5 type='s' icon='clock' /> {dangerousHTML(t('global.min', [{ key: 'MIN', value: bookingDuration }]))}</li>
                                      {!!displayBookedResources && (
                                        <li><CustomerPreviewEventResources event={item} /></li>
                                      )}
                                    </ul>
                                  </div>
                                </SimpleListItemContent>
                                {(isUpcoming && isCancellationForm && fieldsToBeDeleted.length === 0 &&
                                  <SimpleListItemButtons>
                                    <PopupTriggerWrapper
                                      name='bookings-delete' position='top' extras={{
                                        approve: () => onEventRebook({
                                          customer,
                                          service,
                                          branch: eventBranch,
                                          date,
                                          time,
                                          region: item.region,
                                          fields: item.fields,
                                          rescheduledEventId: isScheduleForm ? null : item.id,
                                          rescheduledSecret: isScheduleForm ? null : item.reschedulingSecret,
                                          resources: item.resources,
                                          resourceCategories: item.resourceCategories,
                                          areResourcesFetched: item.areResourcesFetched
                                        })
                                      }}
                                    >
                                      <ListButton
                                        className='ta-btn-primary'
                                        icon='calendar-times'
                                        external
                                        hoverText={t('bookings.cancelAppointment.popup.content')}
                                      />
                                    </PopupTriggerWrapper>
                                  </SimpleListItemButtons>
                                )}
                                {((isUpcoming || isScheduleForm) && !isCancellationForm &&
                                  <SimpleListItemButtons>
                                    <ListButton
                                      className={listButtonClassNames.join(' ')}
                                      icon={icon}
                                      hoverText={t(hoverTextKey)}
                                      action={() => onEventRebook({
                                        customer,
                                        service,
                                        branch: eventBranch,
                                        date,
                                        time,
                                        region,
                                        fields: item.fields,
                                        rescheduledEventId: isScheduleForm ? null : item.id,
                                        rescheduledSecret: isScheduleForm ? null : item.reschedulingSecret,
                                        resources: item.resources,
                                        resourceCategories: item.resourceCategories,
                                        areResourcesFetched: item.areResourcesFetched
                                      })}
                                    />
                                  </SimpleListItemButtons>
                                )}
                              </SimpleListItem>
                            </div>
                          )
                        })}
                      </div>
                      <div className='ta-bookings-buttons-container'>
                        <Row>
                          {(isUpcoming && !!fieldsToBeDeleted.length &&
                            <Col size={50}>
                              <PopupTriggerWrapper name='bookings-delete' position='top' extras={{ approve: () => onCancelBookings(customer) }}>
                                <Button
                                  className='ta-customers__booking-load-more-button'
                                  type='button'
                                  primary
                                  block
                                >
                                  {t('bookings.customerPreview.cancelBookings.label')}
                                </Button>
                              </PopupTriggerWrapper>
                            </Col>
                          )}
                          {(!isBookingsLastPage &&
                            <Col size={isUpcoming && !!fieldsToBeDeleted.length ? 50 : 100}>
                              <Button className='ta-customers__booking-load-more-button' disabled={pending && !message && bookingLength} onClick={loadMoreBookings} primary type='button' greyGhost block>
                                {pending && !message && bookingLength
                                  ? <Spinner />
                                  : t('bookings.customerPreview.loadMoreBookings.label')}
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                  {(!pending && !bookingLength &&
                    <Alert noOffset label={t('customers.tabBookings.section.past.noResultsBox.text')} theme='no-content' />
                  )}
                  {(pending && !bookingLength &&
                    <div className='ta-bookings__loader-container'><Spinner /></div>
                  )}
                </div>
              )
            })}
          </Form>
        </BorderedBox>
      </div>
    </div>
  )
}

CustomerPreviewDetails.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  birthday: PropTypes.string,
  notes: PropTypes.string,
  email: PropTypes.string,
  customFields: PropTypes.arrayOf(PropTypes.object)
}

export default CustomerPreviewDetails
