import React from 'react'
import {
  BookingCustomerLinkWrapper,
  BookingCustomerLinkHeaderWrapper,
  BookingServiceAdhocWrapper,
  BookingServiceAdhocHeaderWrapper,
  BookingLocationWrapper,
  BookingLocationHeaderWrapper,
  BookingDataWrapper,
  BookingSlotPreferencesWrapper,
  BookingSlotPreferencesHeaderWrapper,
  BookingSlotWrapper,
  BookingSlotHeaderWrapper,
  BookingSuccessWrapper
} from '../../../Beasts'
import { BookingFormSection } from '../../../Beauties'
import {
  Title,
  Form,
  FormSection,
  Error,
  t,
  Blur,
  Loader
} from '../../../Common'
import './BookingForm.css'

const BookingScheduleForm = ({
  customerStore,
  onSubmit,
  showHeader,
  setAccordionRef,
  bookingsSchedule,
  customersRead,
  customersWrite,
  pendingTransformation,
  ...props
}) => {
  const { progress, bookingCustomer, isCustomerEdit } = customerStore || {}
  const classNames = ['ta-booking']
  if (isCustomerEdit) classNames.push('is-customer-edit')

  return (
    <Blur extraClassName={bookingsSchedule ? 'inactive' : ''} active={!bookingsSchedule} icon='ban' label={t('global.accessDenied')}>
      <div className='ta-booking__title'>
        <Title label={t('booking.scheduleBooking.header')} />
      </div>
      <Loader active={progress === 1 && pendingTransformation} />
      {progress >= 6
        ? (
          <div className='ta-booking'>
            <BookingSuccessWrapper isScheduleForm />
          </div>
          )
        : (
          <div className={classNames.join(' ')}>
            {(progress >= 0 &&
              <BookingFormSection
                title={t('customerFields.category.basic')}
                className='ta-booking__high-zindex-section'
                header={showHeader && (
                  <BookingCustomerLinkHeaderWrapper
                    key='customer-link-header'
                    noHover
                    stacked
                    customer={bookingCustomer}
                    noListButtons
                  />
                )}
                index={0}
                setAccordionRef={setAccordionRef}
                accordionProps={{
                  hideAccordionToggle: !customersRead
                }}
              >
                <BookingCustomerLinkWrapper key='customer-link' isScheduleForm />
              </BookingFormSection>
          )}
            {(!isCustomerEdit &&
              <Form
                name='booking'
                onSubmit={(e) => onSubmit()}
                scrollWrapper='ta-booking-form__scrollable-content'
              >
                {(progress >= 1 &&
                  <BookingFormSection
                    title={t('booking.serviceAdhoc.header')}
                    header={progress > 1 && <BookingServiceAdhocHeaderWrapper key='service-adhoc-header' />}
                    setAccordionRef={setAccordionRef}
                    index={1}
                  >
                    <BookingServiceAdhocWrapper key='service-adhoc' isScheduleForm />
                  </BookingFormSection>
              )}
                {(progress >= 2 &&
                  <BookingFormSection
                    title={t('booking.location.label')}
                    header={progress > 2 && <BookingLocationHeaderWrapper key='location-header' />}
                    setAccordionRef={setAccordionRef}
                    index={2}
                  >
                    <BookingLocationWrapper key='location' />
                  </BookingFormSection>
              )}
                {(progress >= 3 &&
                  <BookingFormSection
                    title={t('booking.slotPreferences.label')}
                    className='ta-booking__high-zindex-section'
                    header={progress > 3 && <BookingSlotPreferencesHeaderWrapper key='slot-preferences-header' />}
                    setAccordionRef={setAccordionRef}
                    index={3}
                  >
                    <BookingSlotPreferencesWrapper key='slot-preferences' isScheduleForm />
                  </BookingFormSection>
              )}
                {(progress >= 4 &&
                  <BookingFormSection
                    title={t('booking.bookingSlot.label')}
                    header={progress > 4 && <BookingSlotHeaderWrapper />}
                    setAccordionRef={setAccordionRef}
                    index={4}
                  >
                    <BookingSlotWrapper key='book-slot' isScheduleForm />
                  </BookingFormSection>
              )}
                {(progress >= 5 &&
                  <BookingFormSection
                    title={t('scheduleBooking.bookingData.title')}
                    setAccordionRef={setAccordionRef}
                    index={5}
                  >
                    <BookingDataWrapper key='booking-data' isScheduleForm />
                  </BookingFormSection>
              )}
                <FormSection>
                  <Error name='globalErrors' />
                </FormSection>
              </Form>
          )}
          </div>
          )}
    </Blur>
  )
}

export default BookingScheduleForm
