import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Form,
  FormButtons,
  Input,
  // Checkbox,
  SingleSelect,
  Error,
  FontAwesome5,
  Link,
  Alert,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import { WEBAPP_URL } from '../../Settings'

const LoginForm = props => {
  const {
    onSubmit,
    onLanguageChange,
    togglePasswordFieldType,
    passwordFieldType,
    emailWarningStatus,
    userTemporaryBlockedStatus,
    onResendVerificationClick,
    locale,
    hideLanguageSelector,
    // hideRememberMe,
    skipClear
  } = props

  return (
    <div className='ta-login-form'>
      <Form onSubmit={onSubmit} name='login' skipClear={skipClear}>
        <Row>
          <Col>
            <Input
              name='email'
              dataTestid='email'
              label={t('login.fieldEmail.label')}
              placeholder={t('login.fieldEmail.placeholder')}
              mandatory
              hideError
            />
          </Col>
        </Row>
        <Row>
          <Col className='relative'>
            <Input
              name='password'
              dataTestid='password'
              label={t('global.password')}
              placeholder={t('global.password.placeholder')}
              mandatory
              hideError
            />
            <div className='ta-login__password-type-toggle-btn' onClick={togglePasswordFieldType}>
              <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
            </div>
          </Col>
        </Row>
        {(!hideLanguageSelector &&
          <Row>
            <Col>
              <SingleSelect
                name='language'
                onChangeAddon={onLanguageChange}
                label={t('global.language')}
              />
            </Col>
          </Row>
        )}
        {(['warning', 'success'].includes(emailWarningStatus) &&
          <Alert theme={emailWarningStatus + ''}>
            {(emailWarningStatus === 'warning' &&
              <>
                <p>{dangerousHTML(t('login.eMailVerification.note.text'))}</p>
                <Link className='ta-btn ta-btn-primary' onClick={onResendVerificationClick}>
                  {dangerousHTML(t('login.eMailVerification.note.button'))}
                </Link>
              </>
            )}
            {(emailWarningStatus === 'success' && t('login.eMailVerification.note.successText'))}
          </Alert>
        )}
        {(userTemporaryBlockedStatus === 'warning' &&
          <Alert theme='alert'>
            <p>{dangerousHTML(t('login.userTemporaryBlockedStatus.note.text'))}</p>
            <Link
              to={`${WEBAPP_URL}/forgot-password?locale=${locale}&callcentre=1`}
              className='ta-btn ta-btn-primary'
              target='_blank'
              external
            >{dangerousHTML(t('login.userTemporaryBlockedStatus.note.button'))}
            </Link>
          </Alert>
        )}
        <Row>
          {/* {!hideRememberMe &&
            <Col size={50}>
              <Checkbox name='remember' label={t('login.checkboxRemember.label')} />
            </Col>
          } */}
          <Col size={50}>
            <Link
              to={`${WEBAPP_URL}/forgot-password?locale=${locale}&callcentre=1`}
              className='pull-right ta-login__link'
              external
            >{t('global.passwordForgotten')}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Error name='emailOrPassword' data-testid='emailOrPasswordError' />
          </Col>
        </Row>
        <FormButtons>
          <button
            type='submit'
            className='ta-btn ta-btn-primary ta-btn-block'
            disabled={emailWarningStatus === 'warning'}
            data-testid='loginButton'
          >
            {t('login.button')}
          </button>
          {/* <Link to='/login-sso' className='ta-btn ta-btn-black-ghost ta-btn-block ta-login__sso-btn'>
            {t('login.ssoLogin.btn')}
          </Link> */}
        </FormButtons>
      </Form>
    </div>
  )
}

LoginForm.propTypes = {
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  togglePasswordFieldType: PropTypes.func.isRequired,
  passwordFieldType: PropTypes.string.isRequired
}

export default LoginForm
