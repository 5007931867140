import { resourceCategoryFields, resourceFields } from './fields'

export const getAvailableResources = `
query getAvailableResources($serviceId: ID, $dateFrom: DateString, $dateTo: DateString) {
  getAvailableResources(serviceId: $serviceId, dateFrom: $dateFrom, dateTo: $dateTo) {
    availableResources ${resourceFields}
    dependencyName
  }
}`

export const getResourcesLinkedToService = `
query getResourcesLinkedToService($serviceId: ID!, $companyId: ID!, $region: String!) {
  getResourcesLinkedToService(serviceId: $serviceId, companyId: $companyId, region: $region) {
    resources ${resourceFields}
    resourceCategories ${resourceCategoryFields}
  }
}`

export const getResourcesCallCentreBranch = `
query getResourcesCallCentreBranch($filter: ResourcesFilter, $companyId: ID!) {
  getResourcesCallCentreBranch(filter: $filter, companyId: $companyId) ${resourceFields}
}
`

export const getResourceCategoriesCallCentreBranch = `
query getResourceCategoriesCallCentreBranch($companyId: ID!, $filter: ResourcesFilter) {
  getResourceCategoriesCallCentreBranch(companyId: $companyId, filter: $filter) ${resourceCategoryFields}
}
`
