import React from 'react'
import {
  Row,
  Col,
  Checkbox,
  Accordion,
  AccordionTitle,
  AccordionBody,
  HintText,
  SingleSelect,
  Input,
  Radio,
  DateInput,
  FormText,
  Alert,
  FontAwesome5,
  Link,
  Error,
  t
} from '../../../Common'
import {
  getDayLabelByIndex,
  getMonthLabelByIndex,
  sortWeekDays,
  dangerousHTML
} from '../../../Utils'
import { PopupTriggerWrapper } from '../../../Beasts'

const BookingFormRepeat = props => {
  const {
    formType,
    hasRepeat,
    repeatType,
    repeatUntil,
    repeatCustomType,
    areSingleEvents,
    showRepeat,
    recurringType,
    collapsedFormSections,
    onAreSingleEventsClick
  } = props
  const availableWeekDays = sortWeekDays([...Array(7).keys()])
  const availableMonthDays = [...Array(31).keys()]
  const availableMonths = [...Array(12).keys()]
  const repeatTypeOptions = [{
    label: t('calendar.form.section.repeat.type.input.daily.text'),
    value: 'days'
  }, {
    label: t('calendar.form.section.repeat.type.input.weekly.text'),
    value: 'weeks'
  }, {
    label: t('calendar.form.section.repeat.type.input.monthly.text'),
    value: 'months'
  }, {
    label: t('calendar.form.section.repeat.type.input.yearly.text'),
    value: 'years'
  }, {
    label: t('calendar.form.section.repeat.type.input.custom.text'),
    value: 'custom'
  }]
  const repeatCustomTypeOptions = [{
    label: t('global.days').toLowerCase(),
    value: 'days'
  }, {
    label: t('global.weeks').toLowerCase(),
    value: 'weeks'
  }, {
    label: t('global.months').toLowerCase(),
    value: 'months'
  }, {
    label: t('global.years').toLowerCase(),
    value: 'years'
  }]
  const repeatUntilOptions = [{
    label: t('calendar.form.section.repeat.until.endAfter'),
    value: 'times'
  }, {
    label: t('calendar.form.section.repeat.until.endOn'),
    value: 'date'
  }]
  if (areSingleEvents) {
    repeatUntilOptions.push({
      label: t('calendar.form.section.repeat.until.neverEnd'),
      value: 'infinity'
    })
  }

  return (
    <div className='ta-single-booking-form__repeat'>
      {(showRepeat &&
        <>
          <Row>
            <Col>
              <Checkbox name='hasRepeat' label={t('calendar.form.section.repeat.title')} theme='switch' />
            </Col>
          </Row>
          {(hasRepeat &&
            <Accordion section='BookingFormRepeat' expand={!collapsedFormSections.includes('BookingFormRepeat')}>
              <AccordionTitle>{t('calendar.form.section.repeat.title')}</AccordionTitle>
              <AccordionBody>
                {((recurringType === 'onlyThisEvent' || recurringType === 'thisAndFollowingEvents') &&
                  <Alert theme='alert'>
                    {dangerousHTML(t('calendar.form.section.repeat.edit.noteText'))}
                  </Alert>
                )}
                {(recurringType === 'all' &&
                  <>
                    {(formType === 'groupBooking' &&
                      <>
                        <FormText>{t('calendar.form.section.repeat.resultType.title')}</FormText>
                        <Row>
                          <Col>
                            <Radio
                              name='areSingleEvents'
                              value='individual'
                              label={t('calendar.form.section.repeat.resultType.radioIndependent.label')}
                              onChangeAddon={onAreSingleEventsClick}
                              inline
                            />
                            <PopupTriggerWrapper name='courses-independent-recurring' position='top'>
                              <Link className='btn-small-icon-only' external>
                                <FontAwesome5 icon='question-circle' type='regular' />
                              </Link>
                            </PopupTriggerWrapper>
                            {(formType === 'singleBooking' &&
                              <HintText>{t('calendar.form.section.repeat.resultType.radioIndependentService.hint')}</HintText>
                            )}
                            {(formType === 'groupBooking' &&
                              <HintText>{t('calendar.form.section.repeat.resultType.radioIndependentGroup.hint')}</HintText>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio
                              name='areSingleEvents'
                              value='chained'
                              label={t('calendar.form.section.repeat.resultType.radioConnected.label')}
                              inline
                            />
                            <PopupTriggerWrapper name='courses-connected-recurring' position='top'>
                              <Link className='btn-small-icon-only' external>
                                <FontAwesome5 icon='question-circle' type='regular' />
                              </Link>
                            </PopupTriggerWrapper>
                            {(formType === 'singleBooking' &&
                              <HintText>{t('calendar.form.section.repeat.resultType.radioConnectedService.hint')}</HintText>
                            )}
                            {(formType === 'groupBooking' &&
                              <HintText>{t('calendar.form.section.repeat.resultType.radioConnectedGroup.hint')}</HintText>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <SingleSelect
                          name='repeatType'
                          label={t('calendar.form.section.repeat.type.select.label')}
                          options={repeatTypeOptions}
                          searchable
                        />
                      </Col>
                    </Row>
                    {(repeatType === 'custom' &&
                      <div className='ta-single-booking-form__repeat__custom'>
                        <Row>
                          <Col size={40}>
                            <FormText className='ta-single-booking-form__repeat__custom__repeat-every'>
                              {t('calendar.form.section.repeat.type.label')}
                            </FormText>
                          </Col>
                          <Col size={20}>
                            <Input name='repeatCustomFrequency' />
                          </Col>
                          <Col size={40}>
                            <SingleSelect name='repeatCustomType' options={repeatCustomTypeOptions} searchable />
                          </Col>
                        </Row>
                        {(repeatCustomType !== null && repeatCustomType !== 'days' &&
                          <div>
                            {(repeatCustomType === 'weeks' &&
                              <div className='ta-single-booking-form__repeat__custom__options'>
                                {availableWeekDays.map(day => (
                                  <div className='ta-single-booking-form__repeat__custom__option' key={day}>
                                    <Checkbox
                                      name={`repeatCustomWeekDay${day === 7 ? 0 : day}`}
                                      label={t(`weekdays.${getDayLabelByIndex(day === 7 ? 0 : day)}.short`)}
                                      theme='box'
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                            {(repeatCustomType === 'months' &&
                              <div className='ta-single-booking-form__repeat__custom__options'>
                                {availableMonthDays.map(day => (
                                  <div className='ta-single-booking-form__repeat__custom__option' key={day}>
                                    <Checkbox
                                      name={`repeatCustomMonthDay${day + 1}`}
                                      label={day < 9 ? `0${day + 1}` : (day + 1).toString()}
                                      theme='box'
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                            {(repeatCustomType === 'years' &&
                              <div className='ta-single-booking-form__repeat__custom__options'>
                                {availableMonths.map(month => (
                                  <div className='ta-single-booking-form__repeat__custom__option' key={month}>
                                    <Checkbox
                                      name={`repeatCustomMonth${month + 1}`}
                                      label={t(`months.${getMonthLabelByIndex(month + 1)}.short`)}
                                      theme='box'
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {(repeatType !== null &&
                      <Row>
                        <Col size={50}>
                          <SingleSelect name='repeatUntil' options={repeatUntilOptions} searchable />
                        </Col>
                        {(repeatUntil !== null && repeatUntil !== 'infinity' &&
                          <Col size={50}>
                            {(repeatUntil === 'times' &&
                              <Input
                                name='repeatUntilTimes'
                                placeholder={t('calendar.form.section.repeat.endAfter.count.placeholder')}
                                addon={t('calendar.form.section.repeat.endAfter.count.text')}
                              />
                            )}
                            {(repeatUntil === 'date' &&
                              <DateInput
                                name='repeatUntilDate'
                                placeholder={t('calendar.form.section.repeat.until.date.placeholder')}
                              />
                            )}
                          </Col>
                        )}
                      </Row>
                    )}
                  </>
                )}
              </AccordionBody>
              <Error name='bookingRepeatGlobalError' />
            </Accordion>
          )}
        </>
      )}
    </div>
  )
}

export default BookingFormRepeat
